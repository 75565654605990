import { defineComponent, onMounted, ref } from "vue";
import PortalFooter from "@/components/footer/PortalFooter.vue";
import Utils from "@/classes/utils";
import axios from "axios";
import StarRating from "vue-star-rating";
import GoogleMap from "@/components/maps/GoogleMaps.vue";

export default defineComponent({
	name: "RestaurantDetail",
	components: {
		footer: PortalFooter,
		GoogleMap,
		StarRating,
	},
	setup() {
		const utils = new Utils();
		const restaurantName = ref("Restaurante Do João");
		const restaurantLogo = ref("cardapio/logo.png");
		const restaurantCity = ref("Santro André");
		const rating = ref(5);
		const gmapapikey = ref("AIzaSyA0d-BemJmpRr4g4WlBkbiPI7Xi3XqvrJQ");
		/* Google */

		const latitude = ref(-25.4428424); // Example latitude (San Francisco)
		const longitude = ref(-49.2772526); // Example longitude (San Francisco)
		const zoomLevel = ref(14); // Customize the zoom level as needed
		const markerTitle = ref("Jardins Grill");
		const publicStars = ref(0);
		const markerIcon = ref(
			"https://maps.google.com/mapfiles/ms/icons/red-dot.png",
		); // Custom marker icon URL
		/*  */
		const weekPromotions = ref([
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
		]);
		const address = ref(
			"R. Lamenha Lins, 712 - Rebouças, Curitiba - PR, 80220-080",
		);
		const link = ref("https://maps.google.com/maps?saddr=&daddr=");
		const phone = ref("+55 11 1111 - 1111");
		const placeName = ref("Jardins Grill	");
		const menus = ref([
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
			{
				title:
					"Costelinha barbecue 1/2 peça + arroz + batata + farofa + coleslaw",
				description:
					"Costelinha suína defumada por 8 horas, embalada a vácuo com molho barbecue + batata + farofa + coleslaw. Serve de 1 a 2 pessoas.",
				piece: "Serve duas pessoas",
				price: "R$ 53,99",
				image: "cardapio/cardapio.png",
			},
		]);

		const category = ref("fastfood");
		const ifoodLink = ref(
			"https://www.ifood.com.br/delivery/curitiba-pr/cidadao-do-mundo-burger---agua-verde-agua-verde/44baab8a-a54e-4fff-a056-026f6f54ac59",
		);
		const instagramLink = ref("https://www.instagram.com/guiagrandeabc/");
		const greview = ref(0);
		const greviews = ref(null);
		const gpriceLevel = ref("");
		const gopeningHours = ref(null);
		const mapApiPlaceURL = ref("");
		const mapApiPlaceDetailURL = ref("");

		onMounted(() => {
			fetchReviews();
		});

		const fetchReviews = async () => {
			try {
				const response = await getPlaceDataFromGoogle();
				if (response.status !== "OK" || !response.results.length) {
					console.log("estabelecimento não encontrado");
					return;
				}

				const placeId = response.results[0].place_id;

				// Step 2: Get Place Details including reviews
				const detailsResponse = await getPlaceDetailDataFromGoogle(placeId);
				const result = detailsResponse.result;
				greview.value = result.rating;
				gopeningHours.value = result.opening_hours.weekday_text;
				gpriceLevel.value = getPriceLevelSymbols(result.price_level);
				greviews.value = result.reviews;
				publicStars.value = result.rating;
			} catch (error) {
				console.error("Erro ao buscar valiações:", error);
			}
		};

		const getPlaceDataFromGoogle = async () => {
			const query = `${placeName.value} ${address.value}`;

			try {
				mapApiPlaceURL.value = `https://apis.codesolluzi.com/maps/place?query=${encodeURIComponent(
					query,
				)}&key=${gmapapikey.value}&language=pt_BR`;

				const response = await axios.get(mapApiPlaceURL.value);
				const data = response.data;
				return data;
			} catch (error) {
				console.error("Erro ao buscar valiações:", error);
			}
		};

		const getPlaceDetailDataFromGoogle = async (placeId: string) => {
			try {
				mapApiPlaceDetailURL.value = `https://apis.codesolluzi.com/maps/place/	details?place_id=${placeId}&fields=name,rating,reviews,price_level,opening_hours&key=${gmapapikey.value}&language=pt_BR`;

				const response = await axios.get(mapApiPlaceDetailURL.value);
				const data = response.data;

				return data;
			} catch (error) {
				console.error("Erro ao buscar valiações:", error);
			}
		};

		const getPriceLevelSymbols = (priceLevel: number) => {
			switch (priceLevel) {
				case 0:
					return "Free";
				case 1:
					return "$";
				case 2:
					return "$$";
				case 3:
					return "$$$";
				case 4:
					return "$$$$";
				default:
					return "N/A";
			}
		};

		return {
			restaurantName,
			restaurantLogo,
			restaurantCity,
			rating,
			weekPromotions,
			menus,
			utils,
			gmapapikey,
			address,
			link,
			phone,
			category,
			ifoodLink,
			instagramLink,
			greview,
			gopeningHours,
			gpriceLevel,
			greviews,
			latitude, // Example latitude (San Francisco)
			longitude, // Example longitude (San Francisco)
			zoomLevel, // Customize the zoom level as needed
			markerTitle,
			markerIcon,
			publicStars,
		};
	},
});
