<section id="articles">
    <div class="container-full mt-5 mb-5">
        <div class="container">
            <div class="container">
                <div class="container">
                    <div class="container">
                        <h2 class="text-left title title__destaque">Matérias</h2>
                    </div>
                </div>
            </div>
        </div>
        <div :class="postsContainer">
            <div :class="postsContainer">
                <div :class="postsContainer">
                    <div :class="postsContainer">
                        <Carousel
                            :items-to-show="postsItemsToShow"
                            :settings="postsSettings"
                            :breakpoints="postsBreakpoints"
                            :wrap-around="false"
                            @slide-start="postsHandleContainer"
                        >
                            <Slide
                                v-for="post in posts"
                                :key="post"
                            >
                                <div class="carousel__item">
                                    <div class="card box text-start">
                                        <a :href="post.link" class="text-decoration-none text-dark text-color-hover-primary">
                                        <img
                                            :src="getThumbnail(post)" alt="Post Thumbnail" v-if="getThumbnail(post)"
                                            width="166"
                                            height="176"
                                            class="card-img-top rounded-4"
                                            style="padding: 8px"
                                        />
                                        </a>
                                        <div class="card-body">
                                            <h2 class="card-title">
                                                <a :href="post.link" class="text-decoration-none text-dark text-color-hover-primary">
                                                    {{ post.title.rendered }}
                                                </a>
                                            </h2>
                                            <a :href="post.link" class="text-decoration-none text-dark text-color-hover-primary">
                                                <p v-if="post.excerpt && post.excerpt.rendered" v-html="post.excerpt.rendered"></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            <template #addons>
                                <Navigation class="news_navigation" />
                            </template>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container mt-5">
        <h2>Novidades</h2>
        <Carousel :items-to-show="2.5" :wrap-around="true" snap-align="start">
            <Slide v-for="newsArticle in newsArticles" :key="newsArticle">
                <div class="carousel__item">
                    <div class="card box text-start">
                        <img
                            :src="utils.getPit(newsArticle.image)"
                            width="166"
                            height="176"
                            class="card-img-top rounded-5"
                            alt="..."
                            style="padding: 15px"
                        />
                        <div class="card-body">
                            <h2 class="card-title">{{ newsArticle.title }}</h2>
                            <p class="card-text">
                                {{ newsArticle.excert }}
                            </p>
                        </div>
                    </div>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
            </template>
        </Carousel>
    </div>
    <div class="container mt-5 mb-5">
        <h2>Passeios</h2>
        <Carousel :items-to-show="2.5" :wrap-around="true" snap-align="start">
            <Slide v-for="toursArticle in toursArticles" :key="toursArticle">
                <div class="carousel__item">
                    <div class="card box text-start">
                        <img
                            :src="utils.getPit(toursArticle.image)"
                            width="166"
                            height="176"
                            class="card-img-top rounded-5"
                            alt="..."
                            style="padding: 15px"
                        />
                        <div class="card-body">
                            <h2 class="card-title">{{ toursArticle.title }}</h2>
                            <p class="card-text">
                                {{ toursArticle.excert }}
                            </p>
                        </div>
                    </div>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
            </template>
        </Carousel>
    </div> -->
</section>
