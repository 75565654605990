import { createApp } from "vue";
import App from "./app/App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faStar,
	faSearch,
	faArrowRightToBracket,
	faPhone,
	faEnvelope,
	faContactBook,
	faMapMarkedAlt,
	faLocationDot,
	faMobilePhone,
} from "@fortawesome/free-solid-svg-icons";

library.add(
	faStar,
	faSearch,
	faArrowRightToBracket,
	faPhone,
	faWhatsapp,
	faTiktok,
	faInstagram,
	faEnvelope,
	faContactBook,
	faMapMarkedAlt,
	faLocationDot,
	faMobilePhone,
);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
	faInstagram,
	faTiktok,
	faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const app = createApp(App);
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.use(store);
app.use(router);
app.mount("#app");
