<main class="form-signin">
	<form @submit.prevent="signin">
		<h1 class="h3 mb-3 fw-normal">{{ title }}</h1>
		<h2 class="h3 mb-3 fw-normal">Acesse a sua área</h2>

		<div class="form-floating">
			<input
				type="email"
				class="form-control"
				id="floatingInput"
				placeholder="E-mail"
				v-model="formData.email"
			/>
			<label for="floatingInput">E-mail</label>
		</div>

		<div class="form-floating">
			<input
				type="password"
				class="form-control"
				id="floatingPassword"
				placeholder="Senha"
				v-model="formData.password"
			/>
			<label for="floatingPassword">Senha</label>
		</div>

		<div class="checkbox mb-3">
			<label>
				<input
					type="checkbox"
					value="remember-me"
					v-model="formData.remember"
				/>
				Manter conectado
			</label>
		</div>

		<button class="w-100 btn btn-md btn-custom-red" type="submit">
			Acessar
		</button>
	</form>
</main>
