import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container-fluid main mb-5",
  id: "content"
}
const _hoisted_2 = { class: "container mb-5" }

export function render(_ctx, _cache) {
  const _component_login_form = _resolveComponent("login-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_login_form, {
        title: "Parceiro",
        onSignin: _ctx.signin
      }, null, 8, ["onSignin"])
    ])
  ]))
}