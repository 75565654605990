import { defineComponent } from "vue";
import LoginForm from "@/components/login-form/form.vue";

export default defineComponent({
	name: "LoginParceiro",
	components: {
		LoginForm,
	},
	setup() {
		const signin = async (formData: object) => {
			console.log(formData);
		};

		return { signin };
	},
});
