<template>
	<div class="container-fluid main" id="content">
		<section id="banner">
			<div :class="bannerContainer">
				<div :class="bannerContainer">
					<div class="mt-5">
						<div :class="bannerContainer">
							<div :class="bannerContainer">
								<Carousel
									:items-to-show="bannerItemsToShow"
									:settings="bannerSettings"
									:breakpoints="bannerBreakpoints"
									@slide-start="bannerHandleContainer"
								>
									<Slide
										v-for="banner in banners"
										:key="banner"
										:class="banner_carousel"
									>
										<div class="carousel__item banner__item">
											<div class="category">
												<img
													class="my-banner img-fluid mt-1"
													:src="utils.getPit(banner.image)"
													alt="Teste2" style="height: 300px; width: 1222px;"
												/>
											</div>
										</div>
									</Slide>
									<template #addons>
										<Navigation />
										<Pagination style="position: absolute; margin: -5% 45%" />
									</template>
								</Carousel>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="categories">
			<div :class="categoryContainer">
				
					<div class="categories">
						<div :class="categoryContainer">
							<div :class="categoryContainer">
								<div :class="categoryContainer">
									<Carousel
										:items-to-show="categoryItemsToShow"
										:settings="categorySettings"
										:breakpoints="categoryBreakpoints"
										@slide-start="categoryHandleContainer"
									>
										<Slide
											v-for="category in categories"
											:key="category"
											:class="cat_carousel"
										>
											<div class="carousel__item">
												<div class="category rounded-3 box">
													<img
														width="118"
														height="98"
														:src="utils.getPit(category.image)"
														alt="Teste2"
													/>
													<p class="pb-1 pt-1">{{ category.title }}</p>
												</div>
											</div>
										</Slide>
										<template #addons>
											<Navigation class="category__navigation" />
										</template>
									</Carousel>
								</div>
							</div>
						</div>
					</div>
			</div>
		</section>
		<section id="second-navigation">
			<div class="container-fluid" id="abovenav" :class="floatingDivPosition">
				<div class="container" :class="containerSNav">
					<div class="second-navbar">
						<a
							class="btn btn-second-navigation filter__background-color rounded-3"
							>Ordenar</a
						>
						<button
							class="btn btn-second-navigation filter__background-color rounded-3 ms-2 text-weight-bold"
						>
							Entrega Grátis
						</button>
						<button
							class="btn btn-second-navigation filter__background-color rounded-3 ms-2"
						>
							Vale-refeição
						</button>
						<button
							class="btn btn-second-navigation filter__background-color rounded-3 ms-2"
						>
							Distância
						</button>
						<button
							class="btn btn-second-navigation filter__background-color rounded-3 ms-2"
						>
							Entrega Parceira
						</button>
						<button
							class="btn btn-second-navigation filter__background-color rounded-3 ms-2"
						>
							Super Restaurantes
						</button>
						<button
							class="btn btn-second-navigation filter__background-color rounded-3 ms-2"
						>
							Filtros
						</button>
						<button
							class="btn btn-second-navigation filter__background-color rounded-3 ms-2"
						>
							Limpar
						</button>
					</div>
				</div>
			</div>
		</section>
		<section id="restaurants">
			<div class="container-full">
				<div class="container">
					<div class="container">
						<div class="container">
							<div class="container">
								<h2 class="text-left title title__destaque">
									Restaurantes próximos a você
								</h2>
							</div>
						</div>
					</div>
				</div>
				<div :class="closestContainer">
					<div :class="closestContainer">
						<div :class="closestContainer">
							<div :class="closestContainer">
								<div class="mt-3">
										<Carousel
											:items-to-show="closestItemsToShow"
											:settings="closestSettings"
											:breakpoints="closestBreakpoints"
											:wrap-around="true"
											@slide-start="closestHandleContainer"
										>
											<Slide v-for="proximity in closest" :key="proximity">
												<div class="carousel__item">
													<div class="card box text-start">
														
														<img
															:src="utils.getPit(proximity.image)"
															width="166"
															height="200"
															class="card-img-top rounded-4"
															alt="..."
															style="padding: 6px"
														/>
														
														<div class="card-body">
															<h2 class="card-title text-left text-uppercase">
																<a href="/restaurante-do-joao" class="restaurantes">
																	<strong>{{ proximity.name }}</strong>
																</a>
															</h2>
															<p class="card-text mt-3">
																<a href="/restaurante-do-joao" class="restaurantes">
																	<span>{{ proximity.text }}</span>
																</a>
															</p>
														</div>
													</div>
												</div>
											</Slide>
											<template #addons>
												<Navigation class="restaurant__navigation" />
											</template>
										</Carousel>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- <section id="highlights">
			<div class="container">
				<div class="mt-5 mb-3">
					<div class="mt-3">
						<Carousel :items-to-show="3" :wrap-around="true" snap-align="start">
							<Slide v-for="highlight in highlights" :key="highlight">
								<div class="carousel__item">
									<div class="container box">
										<div class="row mt-3 text-start box-width">
											<div class="col" style="padding: 5px">
												<img
													:src="utils.getPit(highlight.image)"
													width="100"
													height="100"
													class="card-img-top rounded-4"
													alt="..."
													style="padding: 2px"
												/>
											</div>
											<div class="col">
												<h2 class="mt-3">{{ highlight.name }}</h2>
												<p>
													<span class="marmita-star">
														<font-awesome-icon :icon="['fas', 'star']" />
														{{ highlight.rate }}
													</span>
													<span> • </span>
													<span class="health">
														{{ highlight.category }}
													</span>
													<span> • </span>
													<span class="distance">
														{{ highlight.distance }}
													</span>
												</p>
												<p class="text">
													<span>{{ highlight.time }}</span>
													<span> • </span>
													<span>{{ highlight.tax }}</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</Slide>
							<template #addons>
								<Navigation />
							</template>
						</Carousel>
					</div>
				</div>
			</div>
		</section> -->
		<!-- <section id="cupom">
			<div class="container">
				<div class="mt-5 mb-3">
					<div class="mt-3">
						<Carousel :items-to-show="3" :wrap-around="true" snap-align="start">
							<Slide v-for="coupon in coupons" :key="coupon">
								<div class="carousel__item">
									<div class="container box">
										<div class="row mt-3 box-width">
											<div class="col" style="padding: 5px">
												<img
													:src="utils.getPit(coupon.image)"
													width="100"
													height="100"
													class="card-img-top rounded-4"
													alt="..."
													style="padding: 2px"
												/>
											</div>
											<div class="col text-start">
												<h2 class="mt-3">{{ coupon.name }}</h2>
												<p class="marmita-formata-text">
													<span class="marmita-star">
														<font-awesome-icon :icon="['fas', 'star']" />
														{{ coupon.rate }}
													</span>
													<span> • </span>
													<span class="health">
														{{ coupon.category }}
													</span>
													<span> • </span>
													<span class="distance">
														{{ coupon.distance }}
													</span>
												</p>
												<p class="text">
													<span>{{ coupon.time }}</span>
													<span> • </span>
													<span>{{ coupon.tax }}</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</Slide>
							<template #addons>
								<Navigation />
							</template>
						</Carousel>
					</div>
				</div>
			</div>
		</section> -->
		<materias />
	</div>
</template>