import { defineComponent, ref } from "vue";

export default defineComponent({
	name: "LoginForm",
	props: {
		title: {
			type: String,
		},
	},
	setup(props, { emit }) {
		const formData = ref({
			email: "",
			password: "",
			remember: false,
		});

		const signin = () => {
			emit("signin", formData.value);
		};

		return { signin, formData };
	},
});
