import { defineComponent, onMounted, ref, watch } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import Utils from "@/classes/utils";

import "vue3-carousel/dist/carousel.css";
import axios from "axios";

export default defineComponent({
	name: "MateriasList",
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
	setup(props, ctx) {
		const utils = new Utils();
		const postsContainer = ref("container");
		const postsItemsToShow = ref(3.5);
		const posts = ref([
			{
				image: "articles/modelo.png",
				title: "Artigo 1",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo II",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo III",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo IV",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo IV",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo IV",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
		]);
		const postsSettings = ref({
			itemsToShow: 5.5,
			snapAlign: "start",
		});
		const postsBreakpoints = ref({
			300: {
				itemsToShow: 1,
				snapAlign: "center",
			},
			700: {
				itemsToShow: 2.5,
				snapAlign: "start",
			},
			1024: {
				itemsToShow: 3.5,
				snapAlign: "start",
			},
		});
		watch(postsContainer, () => {
			postsItemsToShow.value = 4.5;
		});
		const postsHandleContainer = (data: object) => {
			postsContainer.value = "container-full";
			postsBreakpoints.value[700].itemsToShow = 2;
			postsBreakpoints.value[1024].itemsToShow = 4.5;
		};

		onMounted(() => {
			getNews();
		});

		const getNews = async () => {
			try {
				const response = await axios.get(
					process.env.VUE_APP_NEWS + "/wp-json/wp/v2/posts",
					{
						params: {
							per_page: 5,
							order: "desc",
							orderby: "date",
							_embed: true,
						},
					},
				);
				posts.value = response.data;
			} catch (error) {
				console.log(error);
			}
		};

		const getThumbnail = (post: any) => {
			console.log(post._embedded);

			if (
				post._embedded &&
				post._embedded["wp:featuredmedia"] &&
				post._embedded["wp:featuredmedia"][0]
			) {
				return post._embedded["wp:featuredmedia"][0].source_url;
			}
			return null;
		};

		return {
			utils,
			posts,
			getThumbnail,
			postsItemsToShow,
			postsContainer,
			postsSettings,
			postsBreakpoints,
			postsHandleContainer,
		};
	},
});
