import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/home/HomeView.vue";
import RestauranteView from "../views/restaurante/RestauranteView.vue";
import TermosECondicoesDeUso from "../views/termos-e-condicoes-de-uso/termos-e-condicoes-de-uso.vue";
import condigoDeConduta from "@/views/codigo-de-conduta/codigo-de-conduta.vue";
import Privacidade from "@/views/privacidade/privacidade-page.vue";
import SegurancaPage from "@/views/seguranca/seguranca-page.vue";
import ContatoPage from "@/views/contato/contato-page.vue";
import LoginParceiroPage from "@/views/login/parceiro/parceiro.vue";
import LoginUsuarioPage from "@/views/login/usuario/usuario.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: HomeView,
		meta: {
			title: "Guia Grande ABC",
		},
	},
	{
		path: "/restaurante-do-joao",
		name: "restaurante",
		component: RestauranteView,
		meta: {
			title: "Guia Grande ABC | Restaurante",
		},
	},
	{
		path: "/termos-e-condicoes-de-uso",
		name: "termos",
		component: TermosECondicoesDeUso,
		meta: {
			title: "Guia Grande ABC | Termos e Condições de Uso",
		},
	},
	{
		path: "/codigo-de-conduta",
		name: "codigo-de-conduta",
		component: condigoDeConduta,
		meta: {
			title: "Guia Grande ABC | Código de Conduta",
		},
	},
	{
		path: "/privacidade",
		name: "privacidade",
		component: Privacidade,
		meta: {
			title: "Guia Grande ABC | Privacidade",
		},
	},
	{
		path: "/seguranca",
		name: "seguranca",
		component: SegurancaPage,
		meta: {
			title: "Guia Grande ABC | Segurança",
		},
	},
	{
		path: "/contato",
		name: "contato",
		component: ContatoPage,
		meta: {
			title: "Guia Grande ABC | Contato",
		},
	},
	{
		path: "/parceiro",
		name: "parceiro",
		component: LoginParceiroPage,
		meta: {
			title: "Guia Grande ABC | Login",
		},
	},
	{
		path: "/usuario",
		name: "usuario",
		component: LoginUsuarioPage,
		meta: {
			title: "Guia Grande ABC | Login",
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});
/* Router of the apllication */
router.beforeEach((to, from, next) => {
	const titleFromParams = to.params?.pageTitle;
	if (titleFromParams) {
		document.title = `${titleFromParams} - ${document.title}`;
		next();
	} else {
		document.title = to.meta?.title
			? `${to.meta?.title}`
			: "Portal de Restaurantes";
		next();
	}
});

export default router;
