import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-full d-flex flex-column h-100" }

export function render(_ctx, _cache) {
  const _component_main_navigation_bar = _resolveComponent("main-navigation-bar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_portal_footer = _resolveComponent("portal-footer")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_main_navigation_bar),
    _createVNode(_component_router_view),
    _createVNode(_component_portal_footer)
  ]))
}