<div class="container-fluid main" id="content">
    <div class="container">
        <h2 class="text-center" style="margin-top: 150px !important;">Código de Ética e canal de Integridade</h2>
        <p class="h3 text-center"> Acesse </p>
        <p class="text-center">
            Queremos compartilhar tudo aquilo que acreditamos com as pessoas que caminham ao nosso lado.<br> 
            Juntos, podemos alimentar o futuro do mundo com ética e integridade.
        </p>
        
        <div class="container mb-5">
            <div class="container mt-5 text-center">
                <div class="row">
                    <div class="col">
                        <p>Código de Ética e Conduta</p>
                        <a href="javascript:;" class="btn btn-danger btn-lg" target="_blank">
                            Acesse Aqui
                        </a>
                    </div>
                    <div class="col">
                        <p>Terceiros</p>
                        <a href="javascript:;" class="btn btn-danger btn-lg" target="_blank">
                            Acesse Aqui
                        </a>
                    </div>
                    <div class="col">
                        <p>Cartilha de Respeito e Igualdade</p>
                        <a href="javascript:;" class="btn btn-danger btn-lg" target="_blank">
                            Acesse Aqui
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <p class="h3 text-center mt-5">Tem uma situação para reportar?</p>
        <p class="text-center mt-3 mb-5">
            Este é um espaço seguro, confidencial e terceirizado, reforçando a não retaliação ao <br>
            denunciante, onde qualquer pessoa pode comunicar - anonimamente, se desejado - <br>
            condutas que não sejam éticas, transparentes, íntegras e em desacordos com <br>
            regulamentos internos e legislação vigentes.
        </p>
    </div>
</div>