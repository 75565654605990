import { defineComponent, ref } from "vue";

export default defineComponent({
	name: "TermosECondicoesDeUso",
	setup() {
		const wrights = ref([
			{
				title: "1. Como funciona o nosso serviço?",
				text: "Texto de teste...",
			},
			{
				title: "2. Quem pode usar o Guia?",
				text: "Texto de teste...",
			},
			{
				title: "3. Como você foi atendido?",
				text: "Texto de teste...",
			},
			{
				title:
					"4. Informações sobre Preços, Pagamentos, Descontos no Pagamento e Promoções:",
				text: "Texto de teste...",
			},
			{
				title: "5. Informações sobre doações e gorjetas:",
				text: "Texto de teste...",
			},
			{
				title: "6. Informações sobre restaurantes:",
				text: "Texto de teste...",
			},
			{
				title: "7. Declaração de Privacidade de Usuários",
				text: "Texto de teste...",
			},
			{
				title: "8. Propriedade Intelectual",
				text: "Texto de teste...",
			},
			{
				title: "9. Novos Negócios",
				text: "Texto de teste...",
			},
			{
				title: "10. O que o Guia não tolera?",
				text: "Texto de teste...",
			},
			{
				title: "11. O que mais Você precisa saber?",
				text: "Texto de teste...",
			},
		]);

		return { wrights };
	},
});
