import { defineComponent, ref, watch } from "vue";
import MainNavigationBar from "@/components/navbar/MainNavigationBar.vue";
import PortalFooter from "@/components/footer/PortalFooter.vue";
import { useGeolocation } from "@vueuse/core";

export default defineComponent({
	name: "AppIndex",
	components: {
		MainNavigationBar,
		PortalFooter,
	},
	setup() {
		const { coords, error } = useGeolocation();
		const latitude = ref<number | null>(null);
		const longitude = ref<number | null>(null);
		const city = ref("");

		watch(coords, async (newCoords) => {
			if (newCoords.latitude && newCoords.longitude) {
				latitude.value = newCoords.latitude;
				longitude.value = newCoords.longitude;
				console.log(longitude);
			}
		});

		return { error, city };
	},
});
