<header class="p-1 bg-white border-bottom fixed-top navigation">
    <div class="container">
        <div class="container">
        <div class="navbar">
            <div class="container">
            <ul>
                <li>
                    <a href="https://portalguiaabc.codesolluzi.com">
                        <img 
                            src="../../assets/images/logo-extensa.png" 
                            class="rounded-2" 
                            alt="Logo"
                            width="200" 
                        />
                    </a>
                </li>
                <li><a href="/" class="nav-link position-link-left">Início</a></li>
                <li><a :href="newsUrl"  class="nav-link position-link-left">Matérias</a></li>
            </ul>
            <form action="" class="col-7 mb-3 mb-lg-0 me-lg-0 search-form">
                <div class="input-group mb-0">
                    <span class="input-group-text gray-background" id="basic-addon1">
                        <font-awesome-icon :icon="['fas', 'search']" :style="{color: '#ea1d2c'}" />
                    </span>
                    <input 
                        type="text" 
                        class="form-control gray-background search__portal" 
                        placeholder="Busque por restaurante, categoria ou comida" 
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                    />
                </div>
            </form>
            <ul class="login-positioning">
                <li class="dropdown">
                    <a href="#login" class="dropbtn btn nav-link">
                        Login
                        <font-awesome-icon 
                            :icon="['fas', 'arrow-right-to-bracket']" 
                            :style="{color: '#ea1d2c'}"
                            size="lg" 
                        />
                    </a>
                    <div class="dropdown-content">
                        <div class="container p-2">
                            <a href="/parceiro" class="btn btn-custom-red p-1">
                                Parceiro
                            </a>
                            <a href="/usuario" class="btn btn-custom-red p-1 mt-1">Usuário</a>
                        </div>
                        
                    </div>
                </li>
            </ul>
            </div>
        </div>
        </div>
        <!-- <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a href="javascript:;"
                class="d-flex align-items-center mb-2 mb-lg-0 ms-lg-5 text-white text-decoration-none">
                <img src="../../assets/images/logo.png" width="52" height="52" alt="" />
            </a>
            <ul class="nav col-1 mb-2 justify-content-start mb-md-0">
                <li>
                    <a href="" class="nav-link px-2 active">Início</a>
                </li>
            </ul>
            <form action="" class="col-8 mb-3 mb-lg-0 me-lg-0">
                <div class="input-group mb-0">
                    <span class="input-group-text gray-background" id="basic-addon1">
                        <font-awesome-icon :icon="['fas', 'search']" :style="{color: '#ea1d2c'}" />
                    </span>
                    <input type="text" class="form-control gray-background" aria-label="Username"
                        aria-describedby="basic-addon1">
                </div>
            </form>
            <ul class="nav col-2 mb-2 justify-content-end mb-md-0">
                <li>
                    <a href="" class="nav-link px-2 text-secondary link__item">Blog</a>
                </li>
                <li>
                    <a href="" class="nav-link px-2 text-secondary link__item">
                        <font-awesome-icon :icon="['fas', 'arrow-right-to-bracket']" :style="{color: '#ea1d2c'}"
                            size="lg" />
                    </a>
                </li>
            </ul>
        </div> -->
    </div>
</header>