import { ref, onMounted } from "vue";
import { Loader } from "@googlemaps/js-api-loader";

export default {
	name: "GoogleMap",
	props: {
		apiKey: {
			type: String,
			required: true, // Pass your Google Maps API key
		},
		lat: {
			type: Number,
			default: 40.7128, // Default latitude (New York City)
		},
		lng: {
			type: Number,
			default: -74.006, // Default longitude (New York City)
		},
		zoom: {
			type: Number,
			default: 12, // Default zoom level
		},
		markerTitle: {
			type: String,
			default: "Your Location", // Default marker title
		},
		markerIcon: {
			type: String,
			default: null, // Custom marker icon URL
		},
	},
	setup(props: any) {
		const mapContainer = ref();

		onMounted(() => {
			const loader = new Loader({
				apiKey: props.apiKey,
				version: "weekly",
			});

			loader
				.load()
				.then(() => {
					const map = new google.maps.Map(mapContainer.value, {
						center: { lat: props.lat, lng: props.lng },
						zoom: props.zoom,
					});

					new google.maps.Marker({
						position: { lat: props.lat, lng: props.lng },
						map: map,
						title: props.markerTitle,
						icon: props.markerIcon || undefined, // Use custom icon if provided
					});
				})
				.catch((e) => {
					console.error("Google Maps API failed to load:", e);
				});
		});

		return {
			mapContainer,
		};
	},
};
