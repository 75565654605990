<div class="container-fluid main" id="content">
    <div class="container">
        <h2 class="mt-5 text-center" style="margin-top: 150px !important;">
            Termos & Condições de Uso da Plataforma Guia grande ABC para os usuários
        </h2>
        <p class="text-center">Data de atualização: 30 de Julho de 2024</p>
        <p>Olá, usuário</p>
        <p>
            Por meio deste documento, 
            são estabelecidos os termos e as condições gerais para a utilização da Plataforma iFood (“Plataforma iFood”), 
            por Você (“Você”), que é nosso usuário.
        </p>
        <p>
            Nosso maior objetivo é fazer milhares de pessoas felizes, proporcionando momentos únicos e importantes nos seus dias. 
            O nosso propósito é revolucionar o universo da alimentação por uma vida mais prática e prazerosa! Nos enche de orgulho auxiliar 
            a alavancar diversos negócios de inúmeros estabelecimentos parceiros.
        </p>
        <p>Chega mais e vem conferir como funciona o nosso serviço! :)</p>
        <h2>#SuaResponsabilidade</h2>
        <p>
            Esperamos de você uma comunicação respeitosa e não agressiva com os Estabelecimentos, Entregadores e o próprio iFood, 
            sempre zelando pela ética e integridade. Ponto 2.2

            Lembramos que #NãoToleramosNoiFood qualquer forma de preconceito, assédio, bullying e incitação à violência. 
            As condutas que envolverem homofobia, racismo, intolerância religiosa e/ou política, machismo, capacitismo 
            ou qualquer ação que tenha por objetivo diminuir e/ou ofender alguém, estão sujeitas à suspensão ou 
            cancelamento da sua Conta de Usuário em nossa Plataforma iFood, sem aviso prévio e sem prejuízo da adoção de 
            medidas judiciais cabíveis. Ponto 10
        </p>
        <h2>#SeuDireito</h2>
        <p>
            Ao criar uma conta no iFood, nós poderemos coletar algumas informações importantes sobre você.
            Nós sinalizamos para que você fique tranquilo, pois suas informações de pagamento online são armazenadas somente de forma mascarada pelo iFood. 
            Assim, os dados completos de seus cartões não ficam armazenados em nossas bases e suas transações online são 
            feitas com o mais alto padrão de segurança. Ponto 4.9

            Nós mostramos como respeitamos a sua privacidade em nossa missão de proporcionar um serviço cada vez melhor! Ponto 7

            Para saber mais sobre como o iFood obtém, armazena, utiliza, compartilha e protege suas informações, confira a nossa 
            Declaração de Privacidade dos Usuários.
        </p>
        <div class="accordion mb-5" id="accordionExample">
            <div class="accordion-item" v-for="(item, index) in wrights" :key="index">
              <h2 class="accordion-header" :id="`heading-${index}`">
                <button 
                    class="accordion-button" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    :data-bs-target="`#collapse-${index}`" 
                    :aria-controls="`collapse-${index}`"
                >
                  {{ item.title }}
                </button>
              </h2>
              <div 
                :id="`collapse-${index}`" 
                class="accordion-collapse collapse" 
                :aria-labelledby="`heading-${index}`" 
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
    </div>
</div>