<!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
</nav> -->
<div class="container-full d-flex flex-column h-100">
    <main-navigation-bar />
    <router-view />
    <portal-footer />
</div>

