<div class="container-fluid main" id="content">
    <div class="container">
        <h2 class="text-center text-uppercase" style="margin-top: 150px !important;">Dicas de segurança</h2>
        <ul class="mt-5 mb-5">
            <li>
                <strong>Sempre dê preferência a dispositivos e redes de confiança:</strong> Cuidado com aparelhos e redes de 
                internet públicas ou compartilhadas, pois aumenta a exposição das suas informações;
            </li>
            <li>
                <strong>Mantenha seu aplicativo atualizado:</strong> Quanto mais recente for a sua atualização, mais seguro o seu 
                aparelho está. A falta de atualização abre brechas de segurança que podem ser utilizadas por 
                pessoas mal intencionadas;
            </li>
            <li>
                <strong>Proteja sua conta:</strong> nunca compartilhe os códigos que enviamos em seu celular ou e-mail. 
                Isso pode permitir que terceiros acessem sua conta;
            </li>
            <li>
                <strong>Verifique a url:</strong> em pedidos no Guia via desktop (fora do aplicativo) cuidado 
                com páginas falsas com URLs semelhantes a do Guia. Confira se a URL se inicia com guiagrandeabc.com.br” 
                e se é criptografada (identificada pela presença do ‘S’ no ‘HTTP’, o https://).
            </li>
            <li>
                <strong>Confira os comentários das avaliações:</strong> veja o que outros clientes estão falando sobre o 
                restaurante e seus pratos;
            </li>
            <li>
                <strong>Cadastre seu endereço corretamente:</strong> Dê informações precisas sobre o endereço de entrega, 
                com complemento (quando existir) e pontos de referência;
            </li>
            <li>
                <strong>Atenção ao receber:</strong> Quando seu pedido tiver "Entrega Parceira" nós mostraremos quem é o entregador, 
                verifique se é o mesmo da foto exibida no app;
            </li>
            <li>
                <strong>Atenção ao pagar:</strong> Ao pagar no ato da entrega do seu pedido verifique se o valor 
                está correto e caso ocorra algum erro durante a cobrança, confira no seu banco/celular antes de 
                pagar de novo;
            </li>
            <li>
                <strong>Nunca cobramos taxas extras:</strong> caso você tenha escolhido pagar pelo app/site, 
                não é necessário usar a maquininha ao pegar seu pedido. Também não ligamos por telefone para 
                falar sobre taxas adicionais;
            </li>
            <li>
                <strong>Cheque o lacre e as condições da refeição:</strong> Cheque se a refeição está devidamente 
                lacrada e se as condições do pedido estão em conformidade com o cardápio do restaurante;
            </li>
            <li>
                <strong>Atenção com e-mail e SMS falso</strong> - Verifique se o remetente do email é 
                @guiagrandeabc.com.br 
                antes de responder algum contato e não compartilhe seus dados via SMS ou Whatsapp;
            </li>
            <li>
                <strong>Nos avise:</strong> se a refeição não chegou ou teve algum problema nos procure para ajudar a 
                entender como resolver.
            </li>
            <li>
                <strong>De olho na segurança da entrega:</strong> agora, antes de finalizar a entrega do seu pedido, um código poderá 
                ser solicitado pelos entregadores. Consulte este código na área de notificação do seu app.
            </li>
        </ul>
    </div>
</div>
