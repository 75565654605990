<template>
  <section class="mb-5" style="margin-top: 110px;">
    <div class="container main mb-5" id="content">
      <div class="container">
        <div class="container">
          <div class="container">
            <div class="container">
              <div class="row">
                <div class="col restaurant-banner"></div>
              </div>
              <div class="row mt-4 align-items-center">
                <div class="col-md-2" style="padding-left: 0px;">
                  <img 
                  :src="utils.getPit(restaurantLogo)" 
                  class="img-fluid rounded-5" 
                  width="150"
                  :alt="restaurantName"
                  />
                </div>
                <div class="col-md-8">
                  <h1>{{ restaurantName }} - {{ restaurantCity }}</h1>
                </div>
                <div class="col-md-2">
                  <div class="d-flex justify-content-between align-items-center">
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="container-full">
                    <div class="row">
                      <div class="col-md-9 restaurant-menus rounded mb-3">
                        <p class="mt-3">
                          <strong>CATEGORIA:</strong> 
                          <a :href="`/categoria/${category}`" class="link-color">
                            Fastfood
                          </a>
                        </p>
                        <p><strong>FAIXA DE PREÇO:</strong> {{ gpriceLevel}} </p>
                        <p>
                          <strong>GUIA GRANDE ABC: </strong>
                          <star-rating :increment="0.5" :read-only="true" :rating="3.0" :star-size="20" :inline="true"></star-rating>
                        </p>
                        <p>
                          <strong>AVALIAÇÃO DO PÚBLICO:</strong> 
                          <star-rating :increment="0.01" :rating="publicStars" :read-only="true" :star-size="20" :inline="true"></star-rating>
                          &nbsp;
                          <a href="javascript:;" target="_blank" class="link-color" data-bs-toggle="modal" data-bs-target="#reviewModal">Avaliações</a>
                        </p>
                        <p>
                          <strong>HORÁRIO DE FUNCIONAMENTO: </strong> 
                          <a href="javascript:;" target="_blank" class="link-color" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            Clique Aqui
                          </a>
                        </p>
                        <p>
                          <strong>IFOOD: </strong>
                          <a :href="ifoodLink" target="_blank" class="link-color">Clique Aqui</a>
                        </p>
                        <p>
                          <strong>INSTAGRAM: </strong>
                          <a :href="instagramLink" target="_blank" class="link-color">
                            <font-awesome-icon :icon="['fab', 'instagram']" />
                          </a>
                        </p>
                      </div>
                      <div class="col-md-3 border rounded">
                        <h2 class="mt-3 mb-3">Localização e contato</h2>
                        
                          <!-- <img src="@/assets/images/maps.png" class="img-fluid rounded mt-2 mb-2" alt="" srcset=""> -->
                          <GoogleMap 
                          :apiKey="gmapapikey" 
                          :lat="latitude" 
                          :lng="longitude" 
                          :zoom="zoomLevel" 
                          :markerTitle="markerTitle" 
                          :markerIcon="markerIcon" 
                          style="width: 100%;height: 150px;"
                          class="rounded"
                        />   
                          
                          <a :href="link" target="_blank" rel="noopener noreferrer" class="mt-3 d-block restaurant-link">
                            <font-awesome-icon :icon="['fas', 'location-dot']" :style="{color: '#ea1d2c'}" />
                            {{ address }}
                          </a>

                          <a :href="`tel:${phone}`" target="_blank" rel="noopener noreferrer" class="mt-3 d-block mb-3 restaurant-link">
                            <font-awesome-icon :icon="['fas', 'mobile-phone']" :style="{color: '#ea1d2c'}" />
                            {{ phone }}
                          </a>
                        
                      </div>
                      <div class="container p-2">
                        
                        <!-- <div class="container">
                          <h2 class="menu-title">{{ weekPromotion.title }}</h2>
                        </div>
                        <div class="container">
                          <div class="row align-items-center">
                            <div class="col-md-8 menu-description">
                              <span class="align-middle">
                                {{ weekPromotion.description }}
                              </span>
                            </div>
                            <div class="col">
                              <img 
                              :src="utils.getPit(weekPromotion.image)" 
                              class="img-fluid rounded-2" 
                              :alt="weekPromotion.title"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="container">
                          <p class="menu-price">{{ weekPromotion.price }}</p>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
</section>

<!-- HORÁRIO DE FUNCIONAMENTO -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Horários</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <span v-for="(item, index) in gopeningHours" :key="index">
          {{ item }}
          <hr>
        </span>
      </div>
    </div>
  </div>
</div>
<!-- FIM HORÁRIO DE FUNCIONAMENTO -->

<!-- AVALIAÇÕES -->
<div class="modal fade" id="reviewModal" tabindex="-1" aria-labelledby="reviewModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="reviewModalLabel">Avaliações</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container mb-3" v-for="(item, index) in greviews" :key="index">
          <div class="row mb-3">
            <div class="col-md-1">
              <img :src="item.profile_photo_url" width="40px" height="40px" alt="">
            </div>
            <div class="col-md-11 ps-4">
              {{ item.author_name }}<br/>
              <star-rating :increment="0.5" :show-rating="false" :rating="item.rating" :read-only="true" :star-size="10" :inline="true"></star-rating> | {{ item.relative_time_description }}
            </div>
          </div>          
          <div class="row">
              <div class="col d-flex justify-content-between">
                <p>{{ item.text }}</p>
              </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIM AVALIAÇÕES -->

<footer></footer>
</template>