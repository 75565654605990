<template>
    <section id="footer" class="border-top footer">
        <div class="container mb-4 mt-4">
            <div class="row">
                <div class="col-sm col-md-1">
                    <a href="#home">
                        <img
                        src="@/assets/images/logo-final.jpeg"
                        class="rounded-5"
                        width="52"
                        height="52"
                        alt="Logo"
                        />
                    </a>
                </div>
                <div class="col-sm col-md-5">
                    <p class="footer__copyright link">
                        © Copyright 2024 - Guia Grande ABC - Todos os direitos reservados.
                    </p>
                    <p class="footer__copyright link">
                        CNPJ 48.029.808/0001-28
                    </p>
                </div>
                <div class="col-sm">
                    <p class="footer__text-link">
                        <a href="/termos-e-condicoes-de-uso" class="link">Termos e condições de uso</a>
                    </p>
                    <p class="footer__text-link">
                        <a href="/codigo-de-conduta" class="link">Código de conduta</a>
                    </p>
                    <p class="footer__text-link">
                        <a href="/privacidade" class="link">Privacidade</a>
                    </p>
                    <p class="footer__text-link">
                        <a href="/seguranca" class="link">Segurança</a>
                    </p>
                </div>
                <div class="col-sm">
                    <p class="footer__text-link">
                        <a href="/contato" class="link">Contato</a>
                    </p>
                    <ul class="social-icons d-flex justify-content-start" style="margin-left: -10px;">
                        <li>
                            <a href="https://instagram.com" target="_blank" class="link">
                                <font-awesome-icon 
                                :icon="['fab', 'instagram']" 
                                :style="{color: '#ea1d2c'}"
                                size="xs" 
                                />
                            </a>
                        </li>
                        <li>
                            <a href="https://tiktok.com" target="_blank" class="link">
                                <font-awesome-icon 
                                :icon="['fab', 'tiktok']" 
                                :style="{color: '#ea1d2c'}"
                                size="xs" 
                                />
                            </a>
                        </li>
                        <li>
                            <a href="mailto:teste@teste.com" target="_blank" class="link">
                                <font-awesome-icon 
                                :icon="['fas', 'envelope']" 
                                :style="{color: '#ea1d2c'}"
                                size="xs" 
                                />
                            </a>
                        </li>
                        <li>
                            <a href="/contato" class="link">
                                <font-awesome-icon 
                                :icon="['fas', 'contact-book']" 
                                :style="{color: '#ea1d2c'}"
                                size="xs" 
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>