<div class="container-fluid main" id="content">
    <div class="container mb-5">
        <h2 class="text-center text-uppercase" style="margin-top: 150px !important;">Nossos Contatos</h2>
        <h2 class="text-center" style="margin-top: 10px !important;">Telefones</h2>
        <p class="text-center">
            <font-awesome-icon 
                :icon="['fas', 'phone']" 
                :style="{color: '#ea1d2c'}"
                size="xs" 
            />
            (11) 1111-1111
        </p>
        <p class="text-center">
            <font-awesome-icon 
                :icon="['fab', 'whatsapp']" 
                :style="{color: '#ea1d2c'}"
                size="xs" 
            />
            (11) 1111-1111
        </p>
        <h2 class="text-center" style="margin-top: 10px !important;">Redes sociais</h2>
        <ul class="social-icons d-flex justify-content-center" style="margin-left: -10px;">
            <li>
                <a href="https://instagram.com" target="_blank" class="link">
                    <font-awesome-icon 
                        :icon="['fab', 'instagram']" 
                        :style="{color: '#ea1d2c'}"
                        size="xs" 
                    />
                </a>
            </li>
            <li>
                <a href="https://tiktok.com" target="_blank" class="link">
                    <font-awesome-icon 
                        :icon="['fab', 'tiktok']" 
                        :style="{color: '#ea1d2c'}"
                        size="xs" 
                    />
                </a>
            </li>
            <li>
                <a href="mailto:teste@teste.com" target="_blank" class="link">
                    <font-awesome-icon 
                        :icon="['fas', 'envelope']" 
                        :style="{color: '#ea1d2c'}"
                        size="xs" 
                    />
                </a>
            </li>
            <li>
                <a href="/contato" target="_blank" class="link">
                    <font-awesome-icon 
                        :icon="['fas', 'contact-book']" 
                        :style="{color: '#ea1d2c'}"
                        size="xs" 
                    />
                </a>
            </li>
        </ul>
        <h2 class="text-center" style="margin-top: 10px !important;">Endereço</h2>
        <p class="text-center">
            Rua Teste de Curitiba, 1230. Sl 102, <br>Cep: 80.000-000, Curitiba, PR.
        </p>
    </div>
</div>
