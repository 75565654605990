import Utils from "@/classes/utils";
import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import Materias from "@/components/materias/materias.vue";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
	name: "HomePage",
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
		Materias,
	},
	setup() {
		const utils = new Utils();
		const floatingDivPosition = ref("text-center");
		const containerSNav = ref("p-0");
		const cat_carousel = ref("carousel__categories__slide");
		const banner_carousel = ref("carousel__banner__slide");

		const bannerItemsToShow = ref(1);
		const bannerContainerKey = ref(0);
		const bannerContainer = ref("container");
		const banners = ref([
			{
				image: "banners/modelo.png",
			},
			{
				image: "banners/restaurante-5.png",
			},
			{
				image: "banners/modelo.png",
			},
			{
				image: "banners/restaurante-5.png",
			},
		]);
		const bannerSettings = ref({
			itemsToShow: 1,
			snapAlign: "start",
		});
		const bannerBreakpoints = ref({
			300: {
				itemsToShow: 1,
				snapAlign: "start",
			},
			700: {
				itemsToShow: 1,
				snapAlign: "start",
			},
			1024: {
				itemsToShow: 1,
				snapAlign: "start",
			},
		});
		watch(bannerContainer, () => {
			bannerItemsToShow.value = 1.5;
			banner_carousel.value = "carousel__banner__slide";
		});

		// Categories
		const categoryItemsToShow = ref(8.3);
		const categoryContainer = ref("container");
		const categories = ref([
			{
				image: "categories/lanches.avif",
				title: "Lanches",
			},
			{
				image: "categories/lanches.avif",
				title: "Saudável",
			},
			{
				image: "categories/lanches.avif",
				title: "Pizza",
			},
			{
				image: "categories/lanches.avif",
				title: "Promoções",
			},
			{
				image: "categories/lanches.avif",
				title: "Japonesa",
			},
			{
				image: "categories/lanches.avif",
				title: "Brasileira",
			},
			{
				image: "categories/lanches.avif",
				title: "Doces & Bolos",
			},
			{
				image: "categories/lanches.avif",
				title: "Árabe",
			},
			{
				image: "categories/lanches.avif",
				title: "Açaí",
			},
			{
				image: "categories/lanches.avif",
				title: "Lanches1",
			},
			{
				image: "categories/lanches.avif",
				title: "Saudável1",
			},
			{
				image: "categories/lanches.avif",
				title: "Pizza1",
			},
			{
				image: "categories/lanches.avif",
				title: "Promoções1",
			},
			{
				image: "categories/lanches.avif",
				title: "Japonesa1",
			},
			{
				image: "categories/lanches.avif",
				title: "Brasileira1",
			},
			{
				image: "categories/lanches.avif",
				title: "Doces & Bolos1",
			},
			{
				image: "categories/lanches.avif",
				title: "Árabe1",
			},
			{
				image: "categories/lanches.avif",
				title: "Açaí1",
			},
		]);
		const categorySettings = ref({
			itemsToShow: 8.3,
			snapAlign: "start",
		});
		const categoryBreakpoints = ref({
			700: {
				itemsToShow: 6.3,
				snapAlign: "start",
			},
			1024: {
				itemsToShow: 8.3,
				snapAlign: "start",
			},
		});
		watch(categoryContainer, () => {
			categoryItemsToShow.value = 8.3;
			cat_carousel.value = "carousel__categories__slide__2";
		});

		// Closest
		const closestItemsToShow = ref(5.5);
		const closestContainer = ref("container");
		const closest = ref([
			{
				image: "closest/modelo.png",
				name: "Restaurante do José",
				value: "R$ 12,90",
				text: "Descrição do restaurante em poucas palavras",
				discount: "-1%",
				total: "R$ 10,90",
				tax: "Grátis",
				time: "10-20 min",
			},
			{
				image: "closest/modelo.png",
				name: "Costelão do gaucho",
				value: "R$ 22,90",
				text: "Descrição do restaurante em poucas palavras",
				discount: "-2%",
				total: "R$ 20,90",
				tax: "R$ 10,00",
				time: "20-30 min",
			},
			{
				image: "closest/modelo.png",
				name: "Boi Dourado",
				value: "R$ 32,90",
				text: "Descrição do restaurante em poucas palavras",
				discount: "-3%",
				total: "R$ 30,90",
				tax: "Grátis",
				time: "30-40 min",
			},
			{
				image: "closest/modelo.png",
				name: "Madalosso",
				value: "R$ 42,90",
				text: "Descrição do restaurante em poucas palavras",
				discount: "-4%",
				total: "R$ 40,90",
				tax: "Grátis",
				time: "40-50 min",
			},
			{
				image: "closest/modelo.png",
				name: "Ravello",
				value: "R$ 52,90",
				text: "Descrição do restaurante em poucas palavras",
				discount: "-5%",
				total: "R$ 50,90",
				tax: "Grátis",
				time: "50-60 min",
			},
			{
				image: "closest/modelo.png",
				name: "Mineiro",
				value: "R$ 62,90",
				text: "Descrição do restaurante em poucas palavras",
				discount: "-10%",
				total: "R$ 60,90",
				tax: "Grátis",
				time: "60-70 min",
			},
		]);
		const closestSettings = ref({
			itemsToShow: 5.5,
			snapAlign: "center",
		});
		const closestBreakpoints = ref({
			300: {
				itemsToShow: 1,
				snapAlign: "center",
			},
			700: {
				itemsToShow: 1,
				snapAlign: "start",
			},
			1024: {
				itemsToShow: 5.5,
				snapAlign: "start",
			},
		});
		watch(closestContainer, () => {
			closestItemsToShow.value = 7;
		});

		// Highlights Articles
		const highlightsArticlesItemsToShow = ref(3.5);
		const highlightsArticlesContainer = ref("container");
		const highlightsArticles = ref([
			{
				image: "articles/modelo.png",
				title: "Artigo 1",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo II",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo III",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo IV",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo IV",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "articles/modelo.png",
				title: "Artigo IV",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
		]);
		const highlightsArticlesSettings = ref({
			itemsToShow: 5.5,
			snapAlign: "start",
		});
		const highlightsArticlesBreakpoints = ref({
			300: {
				itemsToShow: 1,
				snapAlign: "center",
			},
			700: {
				itemsToShow: 2.5,
				snapAlign: "start",
			},
			1024: {
				itemsToShow: 3.5,
				snapAlign: "start",
			},
		});
		watch(highlightsArticlesContainer, () => {
			highlightsArticlesItemsToShow.value = 4.5;
		});

		const highlights = ref([
			{
				image: "closest/1.avif",
				name: "Restaurante do Mineiro",
				rate: "2.9",
				category: "Lanches",
				distance: "1.5 km",
				time: "10-20 min",
				tax: "R$ 9,90",
			},
			{
				image: "closest/2.avif",
				name: "Restaurante do Gaúcho",
				rate: "3.9",
				category: "Saudável",
				distance: "2.5 km",
				time: "10-20 min",
				tax: "R$ 9,90",
			},
			{
				image: "closest/3.avif",
				name: "Restaurante do Catarinense",
				rate: "4.9",
				category: "Pizza",
				distance: "3.5 km",
				time: "10-20 min",
				tax: "R$ 9,90",
			},
			{
				image: "closest/4.avif",
				name: "Restaurante do Paranaense",
				rate: "5.0",
				category: "Promoções",
				distance: "4.5 km",
				time: "10-20 min",
				tax: "R$ 9,90",
			},
		]);
		const coupons = ref([
			{
				image: "closest/1.avif",
				name: "Restaurante do Japa",
				rate: "2.9",
				category: "Japonesa",
				distance: "1.5 km",
				time: "10-20 min",
				tax: "R$ 9,90",
			},
			{
				image: "closest/2.avif",
				name: "Cantinho do João",
				rate: "3.9",
				category: "Brasileira",
				distance: "2.5 km",
				time: "10-20 min",
				tax: "R$ 9,90",
			},
			{
				image: "closest/3.avif",
				name: "Padaria da Joana",
				rate: "4.9",
				category: "Doces & Bolos",
				distance: "3.5 km",
				time: "10-20 min",
				tax: "R$ 9,90",
			},
			{
				image: "closest/4.avif",
				name: "Esquina da Esfiha",
				rate: "5.0",
				category: "Árabe",
				distance: "4.5 km",
				time: "10-20 min",
				tax: "R$ 9,90",
			},
		]);

		const newsArticles = ref([
			{
				image: "closest/4.avif",
				title: "Artigo IV",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "javascript:;",
			},
			{
				image: "closest/3.avif",
				title: "Artigo III",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "closest/2.avif",
				title: "Artigo II",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "closest/1.avif",
				title: "Artigo 1",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
		]);
		const toursArticles = ref([
			{
				image: "closest/1.avif",
				title: "Artigo I",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "javascript:;",
			},
			{
				image: "closest/2.avif",
				title: "Artigo II",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "closest/3.avif",
				title: "Artigo III",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
			{
				image: "closest/4.avif",
				title: "Artigo IV",
				excert:
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
				url: "",
			},
		]);

		onMounted(() => {
			window.addEventListener("scroll", handleScroll);
		});

		onUnmounted(() => {
			window.removeEventListener("scroll", handleScroll);
		});

		const handleScroll = () => {
			const navbar = document.querySelector(".navigation") as HTMLElement;
			const content = document.getElementById(
				"second-navigation",
			) as HTMLElement | null;

			if (navbar && content) {
				const navbarHeight = navbar.offsetHeight;
				const contentTop = content.offsetTop; // Use offsetTop to get the top position of the content
				if (window.scrollY >= contentTop - navbarHeight) {
					floatingDivPosition.value = "abovenav text-center";
					containerSNav.value = " p-3";
				} else {
					floatingDivPosition.value = "text-center";
					containerSNav.value = "p-0";
				}
			}
		};

		// eslint-disable-next-line
		const handleLastSlide = (data: any) => {
			if (data.currentSlideIndex === 3.5) {
				window.open("https://globo.com", "blank");
			}
		};

		const categoryHandleContainer = (data: object) => {
			categoryContainer.value = "container-full";
			categoryBreakpoints.value[1024].itemsToShow = 6.3;
		};

		const bannerHandleContainer = (data: object) => {
			bannerContainer.value = "container-full";
			bannerBreakpoints.value[700].itemsToShow = 1;
			bannerBreakpoints.value[1024].itemsToShow = 1.5;
		};

		const closestHandleContainer = (data: object) => {
			closestContainer.value = "container-full";
			closestBreakpoints.value[700].itemsToShow = 2;
			closestBreakpoints.value[1024].itemsToShow = 7.5;
		};

		const highlightsArticlesHandleContainer = (data: object) => {
			highlightsArticlesContainer.value = "container-full";
			highlightsArticlesBreakpoints.value[700].itemsToShow = 2;
			highlightsArticlesBreakpoints.value[1024].itemsToShow = 4.5;
		};

		return {
			categories,
			categorySettings,
			categoryHandleContainer,
			categoryContainer,
			categoryBreakpoints,
			categoryItemsToShow,
			bannerItemsToShow,
			banners,
			bannerSettings,
			bannerBreakpoints,
			bannerContainer,
			bannerHandleContainer,
			bannerContainerKey,
			closestItemsToShow,
			closestContainer,
			closest,
			closestSettings,
			closestHandleContainer,
			closestBreakpoints,
			highlights,
			coupons,
			utils,
			handleLastSlide,
			highlightsArticles,
			highlightsArticlesItemsToShow,
			highlightsArticlesContainer,
			highlightsArticlesSettings,
			highlightsArticlesBreakpoints,
			highlightsArticlesHandleContainer,
			newsArticles,
			toursArticles,
			floatingDivPosition,
			containerSNav,
			cat_carousel,
			banner_carousel,
		};
	},
});
