/* eslint-disable */
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "MainNavigationBar",
  setup() {
    const newsUrl = ref(process.env.VUE_APP_NEWS);

    return {
      newsUrl,
    }
  },
});
